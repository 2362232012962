(function($, undefined) {
	
	'use strict';	

	var init = function (page) {
		var currPage = $(page);
		
		var activeTab = sessionStorage.getItem('admin-current-tab');
		if(activeTab) {
			switchTab(activeTab);
		} else {
			switchTab('.admin-quote-requests-ctn');
		}
		
		$('.admin-tab-ctn').click(function() {
			var selector = $(this).data('toggleLink');
			sessionStorage.setItem('admin-current-tab', selector);
			switchTab(selector);
		});
		
		localStorage.setItem('pageId', '');
	};
	
	
	function switchTab(newTabSelector) {
		$('.admin-tab-content').each(function() {
			if ($(this).hasClass('show')) {
				$(this).removeClass('show');
			}
		});
		$(newTabSelector).addClass('show');
	}
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3741' : '3299';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
