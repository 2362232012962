(function($, undefined) {
	
	'use strict';
	var win;
	var winWidth;
	var winHeight;
	var items;
	
	var autoScreenHeight = function () {
		items.each(function () {
			var t = $(this);
			var attr = t.data('autoScreenAttr') === undefined ? 'min-height' : t.attr('data-auto-screen-attr');
			var offset = t.data('autoScreenOffset') !== undefined ? t.data('autoScreenOffset').replace(/\s+/g, '').split(',') : 0;
			var factor = t.data('autoScreenFactor') !== undefined ? t.data('autoScreenFactor') : 1;
			var offsetHeight = 0;
			
			$.each(offset,function (i) {
				var t = $(offset[i]);
				offsetHeight += t.height() + parseInt(t.css('border-top-width')) + parseInt(t.css('border-bottom-width')) +
					parseInt(t.css('padding-top')) + parseInt(t.css('padding-bottom'));
				
			});
			
			var newHeight = (winHeight * factor) - offsetHeight;
			
			t.css(attr,newHeight);
		});
	};
	
	
	var init = function (page) {
		var currPage = $(page);
		
		win = $(window);
		winWidth = win.width();
		winHeight = win.height();
		items = $('.js-auto-screen-height');
		
		autoScreenHeight();
		
		win.resize(function() {
			winWidth = win.width();
			winHeight = win.height();
			
			autoScreenHeight();
		});
	};
	
	
	var modAutoScreenHeight = Fw.modules.export('modAutoScreenHeight', {
		pageLoad: init
	});
	
})(jQuery);
