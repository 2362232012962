(function($, undefined) {
	
	'use strict';
	
	var form = Fw.component.models().form;
	
	
	var init = function (page) {
		var currPage = $(page);

		var desktopCategories = $('.desktop-categories ul li', currPage);
		var mobileCategoriesSelect = $('.mobile-categories select', currPage);
		var blogPosts = $('.masterpiece-post-ctn', currPage);
		var blogPostsCtnEmptyMsg = $('.masterpiece-ctn-inner div.empty', currPage);
		
		
		desktopCategories.click(function() {
			desktopCategories.removeClass('selected');
			$(this).addClass('selected');
			var selectedCategoryId = $(this).data('id');
			var found = false;
			blogPostsCtnEmptyMsg.hide();
			
			if (selectedCategoryId !== -1) {
				blogPosts.hide();
				blogPosts.each(function() {
					if ($(this).data('categoryId') === selectedCategoryId) {
						$(this).show();
						found = true;
					}
				});
				if(!found) {
					blogPostsCtnEmptyMsg.show();
				}
			}
			else {
				blogPosts.show();
			}
			
		});
		
		mobileCategoriesSelect.change(function() {
			var selectedCategoryId = $(this).find(':selected').val();
			
			var found = false;
			blogPostsCtnEmptyMsg.hide();
			
			if(selectedCategoryId !== "-1") {
				blogPosts.hide();
				blogPosts.each(function() {
					if($(this).data('categoryId') == selectedCategoryId) {
						$(this).show();
						found = true;
					}
				});
				if(!found) {
					blogPostsCtnEmptyMsg.show();
				}
			}
			else {
				blogPosts.show();
			}
		});
		
		form.init(currPage);
		
		localStorage.setItem('pageId', '');
	};
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3708' : '3285';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
