(function($, undefined) {
	
	'use strict';
	
	var form = Fw.component.models().form;
	
	var prevLink = $('#product-detail-page-prev');
	
	
	var init = function (page) {
		var currPage = $(page);
		
		var previousPage = localStorage.getItem('pageId');
		if (previousPage && (previousPage == "3709" || previousPage == "3286")) { // new arrivals
			$('.marketplace-cat-prev').hide();
			$('.new-arrivals-prev').show();
		}
		else { //marketplace categories
			$('.marketplace-cat-prev').show();
			$('.new-arrivals-prev').hide();
		}
		
		$('.product-detail-images-ctn').slick({
			slidesToShow: 4,
			infinite: true,
			dots: true,
			responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 3,
			        slidesToScroll: 1
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    }
			]
		});
		
		var zoomSlick = $('.product-detail-images-ctn-zoom-slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			dots: false,
			fade: true,
			cssEase: 'linear'
		});
		
		$(".product-detail-slide:not(.zoom-img)").click(function(e) {
			if(!$(e.target).hasClass('delete-img-btn')) {
				var slideClickedIndex = $(this).data('slickIndex');
			
				$('.product-detail-images-ctn-zoom').addClass('show');
				$('body').addClass('zoom');
				$('.zoom-toggler').addClass('show');
				zoomSlick.slick('slickGoTo', slideClickedIndex);
			}
		});
		$('.zoom-toggler').click(function() {
			$('.product-detail-images-ctn-zoom').removeClass('show');
			$('body').removeClass('zoom');
			$('.zoom-toggler').removeClass('show');
		});
		
		// from http://stackoverflow.com/questions/37026847/add-bookmark-this-page-button-2016
		$('.bookmark-link', currPage).click(function(e) {
			e.preventDefault();
		    var bookmarkURL = window.location.href;
		    var bookmarkTitle = document.title;

		    if ('addToHomescreen' in window && window.addToHomescreen.isCompatible) {
		        // Mobile browsers
		        addToHomescreen({ autostart: false, startDelay: 0 }).show(true);
		    } else if (window.sidebar && window.sidebar.addPanel) {
		        // Firefox version < 23
		        window.sidebar.addPanel(bookmarkTitle, bookmarkURL, '');
		    } else if ((window.sidebar && /Firefox/i.test(navigator.userAgent)) || (window.opera && window.print)) {
		        // Firefox version >= 23 and Opera Hotlist
		        $(this).attr({
		            href: bookmarkURL,
		            title: bookmarkTitle,
		            rel: 'sidebar'
		        }).off(e);
		        return true;
		    } else if (window.external && ('AddFavorite' in window.external)) {
		        // IE Favorite
		        window.external.AddFavorite(bookmarkURL, bookmarkTitle);
		    } else {
		        // Other browsers (mainly WebKit - Chrome/Safari)
		        alert('Please press ' + (/Mac/i.test(navigator.userAgent) ? 'CMD' : 'Strg') + ' + D to add this page to your favorites.');
		    }

		    return false;
		});
		
		
		$('.add-to-cart-btn').click(function() {
			var button = $(this);
			var url = "/action/shopAddCart?productId=" + button.data('productId');
			url += "&ajax=true";
			
			$.ajax({
				url: url,
				method: 'POST',
				success: function(response) {
					if (response.indexOf("success") != -1)  {
						$("html, body").animate({ scrollTop: 0 }, "slow");
						setTimeout(function() {
							var cartCount = parseInt($('.cart-items-quantity').html());
							$('.cart-items-quantity').html(cartCount + 1);
							
							$('.item-name-popup').html(button.data('itemName'));
							$('.item-price-popup').html(button.data('itemPrice') + '$');
							$('.added-to-cart-info').addClass('show');
							setTimeout(function() {
								$('.added-to-cart-info').removeClass('show');
							}, 4000);
						}, 500);
						
					}
				},
				error: function(xhr, status, err) {
					console.log("There was an error adding the item to the cart " + err);
				}
			});
		});
		
		
		
		/* Admin delete mediaId image */
		$('.delete-img-btn-ctn').click(function() {
			var btn = $(this);
			var mediaId = btn.data('mediaId');
			var productId = btn.data('productId');
			var url = "/action/deleteImageGallery?mediaId=" + mediaId + "&productId=" + productId + "&ajax=true";
			
			$.ajax({
				url: url,
				method: 'post',
				success: function(response) {
					if (response.trim() === "success") {
						btn.parents('.product-detail-slide').hide();
					}
				},
				error: function(jqxhr, status, error) {
					console.log(status + " " + error);
				}
			});
		});
			
		form.init(currPage);
		
	};
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3730' : '3278';
	var home = Fw.page.export(pageId, {
		init: init
	});

	
	
})(jQuery);
