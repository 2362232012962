(function($, undefined) {
	
	'use strict';
	
	var form = Fw.component.models().form;
	
	
	var init = function (page) {
		var currPage = $(page);
		
		form.init(currPage);
		localStorage.setItem('pageId', '');
	};
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3710' : '3287';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
