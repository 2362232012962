(function($, undefined) {
	
	'use strict';
	
	var prevState = "desktop";
	var state = "desktop";
	
	var roundDecimal = 6;
	var form = Fw.component.models().form;
	var isMobileDiv = $('.is-mobile');
	
	function isMobile() {
		
		return isMobileDiv.css('visibility') === "visible";
	}
	
	function setState() {
		if (isMobile()) {
			return "mobile";
		}
		else {
			return "desktop";
		}
	}
	
	var init = function (page) {
		var currPage = $(page);
		
		/* drawers part */
		$('.conversion-tools-subcategory-toggler').on('click', function(event) {
			$(this).parent().siblings('.unit-category-form-ctn-outer').slideToggle();
			$(this).toggleClass('open');
		});	
		$(window).resize(function() {
			state = setState();
			if (state !== prevState && state == "desktop") {
				$('.conversion-tools-subcategory-toggler').parent().siblings('.unit-category-form-ctn-outer').show();
				$('.conversion-tools-subcategory-toggler').removeClass('open');
			}
			else if (state !== prevState) {
				$('.conversion-tools-subcategory-toggler').parent().siblings('.unit-category-form-ctn-outer').hide();
			}
			prevState = state;
		});
		
		/* conversion part*/
		$('.unit-category-ctn.length input').on('input', function() {
			convertLength($(this));
		});
		$('.unit-category-ctn.weight input').on('input', function() {
			convertWeight($(this));
		});
		$('.unit-category-ctn.pressure input').on('input', function() {
			convertPressure($(this));
		});
		$('.unit-category-ctn.volume input').on('input', function() {
			convertVolume($(this));
		});
		$('.unit-category-ctn.volume select').on('change', function() {
			convertVolume($(this));
		});
		$('.unit-category-ctn.degrees input').on('input', function() {
			convertDegree($(this));
		});
		
		form.init(currPage);
		localStorage.setItem('pageId', '');
	};
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3728' : '3291';
	var home = Fw.page.export(pageId, {
		init: init
	});

	
	/* LENGTH FUNCTIONS */
	var toMetersRouter = function(unit) {
		
		switch(unit) {
			case "mm": return function(mm) {
				return round(parseFloat(mm) / 1000.0);
			};
			case "cm": return function(cm) {
				return round(parseFloat(cm) / 100.0);
			};
			case "in": return function(inches) {
				return round(parseFloat(inches) * 0.0254);
			};
			case "ft": return function(ft) {
				return round(parseFloat(ft) * 0.3048);
			};
			case "yd": return function(yd) {
				return round(parseFloat(yd) * 0.9144);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	var fromMetersRouter = function(unit) {
		switch(unit) {
			case "mm": return function(m) {
				return round(parseFloat(m) * 1000.0);
			};
			case "cm": return function(m) {
				return round(parseFloat(m) * 100.0);
			};
			case "in": return function(m) {
				return round(parseFloat(m) / 0.0254);
			};
			case "ft": return function(m) {
				return round(parseFloat(m) / 0.3048);
			};
			case "yd": return function(m) {
				return round(parseFloat(m) / 0.9144);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	
	/* WEIGHT FUNCTIONS */
	var toGrammesRouter = function(unit) {
		switch(unit) {
			case "kg": return function(kg) {
				return round(parseFloat(kg) * 1000.0);
			};
			case "mg": return function(mg) {
				return round(parseFloat(mg) / 1000.0);
			};
			case "lb": return function(lb) {
				return round(parseFloat(lb) * 453.592);
			};
			case "t": return function(t) {
				return round(parseFloat(t) * 1000000);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	var fromGrammesRouter = function(unit) {
		switch(unit) {
			case "kg": return function(g) {
				return round(parseFloat(g) / 1000.0);
			};
			case "mg": return function(g) {
				return round(parseFloat(g) * 1000.0);
			};
			case "lb": return function(g) {
				return round(parseFloat(g) / 453.592);
			};
			case "t": return function(g) {
				return round(parseFloat(g) / 1000000);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	
	/* PRESSURE FUNCTIONS */
	var toBarRouter = function(unit) {
		switch(unit) {
			case "kgcm": return function(kgcm) {
				return round(parseFloat(kgcm) * 0.980665);
			};
			case "psi": return function(psi) {
				return round(parseFloat(psi) / 14.5038);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	var fromBarRouter = function(unit) {
		switch(unit) {
			case "kgcm": return function(bar) {
				return round(parseFloat(bar) / 0.980665);
			};
			case "psi": return function(bar) {
				return round(parseFloat(bar) * 14.5038);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	
	/* LENGTH FUNCTIONS */
	var toLitresRouter = function(unit) {
		
		switch(unit) {
			case "pinte": return function(pinte) {
				return round(parseFloat(pinte) * 0.473176);
			};
			case "galus": return function(galus) {
				return round(parseFloat(galus) * 3.78541);
			};
			case "galimp": return function(galimp) {
				return round(parseFloat(galimp) * 4.54609);
			};
			case "ft3": return function(ft3) {
				return round(parseFloat(ft3) * 28.3168);
			};
			case "yd3": return function(yd3) {
				return round(parseFloat(yd3) * 764.555);
			};
			case "m3": return function(m3) {
				return round(parseFloat(m3) * 1000);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	var fromLitresRouter = function(unit) {
		switch(unit) {
			case "pinte": return function(l) {
				return round(parseFloat(l) / 0.473176);
			};
			case "galus": return function(l) {
				return round(parseFloat(l) / 3.78541);
			};
			case "galimp": return function(l) {
				return round(parseFloat(l) / 4.54609);
			};
			case "ft3": return function(l) {
				return round(parseFloat(l) / 28.3168);
			};
			case "yd3": return function(l) {
				return round(parseFloat(l) / 764.555);
			};
			case "m3": return function(l) {
				return round(parseFloat(l) / 1000);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	/* DEGREE FUNCTIONS */
	var toCelsiusRouter = function(unit) {
		
		switch(unit) {
			case "f": return function(f) {
				return round((parseFloat(f) - 32) / 1.8);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	var fromCelsiusRouter = function(unit) {
		switch(unit) {
			case "f": return function(c) {
				return round((parseFloat(c) * 1.8 ) + 32);
			};
			default: console.log("unit " + unit + " not recognized");
		}
	};
	
	
	function convertLength(changedInput) {
		
		if (changedInput.val() !== "") {
			if(changedInput.val().indexOf(',') != -1) {
				changedInput.val(changedInput.val().replace(/,/g, '.'));
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-' && isNaN(changedInput.val())) {
				alert("You did not enter a numeric value!");
				return;
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-') {
				if (!changedInput.hasClass( "m")) {
					var valueInMeters = toMetersRouter(changedInput.attr('name'))(changedInput.val());
					$('.unit-category-ctn.length input.m').val(valueInMeters);
				}
				//change all inputs according to meters value
				var metersValue = $('.unit-category-ctn.length input.m').val();
				$('.unit-category-ctn.length input').each(function() {
					if (!$(this).hasClass("m") && !$(this).hasClass(changedInput.attr('name'))) {
						var unit = $(this).attr('name');
						var calculatedValue = fromMetersRouter(unit)(metersValue);
						$(this).val(calculatedValue);
					}
				});
			}
		}
		else {
			$('.unit-category-ctn.length input').each(function() {
				$(this).val("");
			});
		}
	}
	function convertWeight(changedInput) {
		if (changedInput.val() !== "") {
			if(changedInput.val().indexOf(',') != -1) {
				changedInput.val(changedInput.val().replace(/,/g, '.'));
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-' && isNaN(changedInput.val())) {
				alert("You did not enter a numeric value!");
				return;
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-') {
				if (!changedInput.hasClass("g")) {
					var valueInGrammes = toGrammesRouter(changedInput.attr('name'))(changedInput.val());
					$('.unit-category-ctn.weight input.g').val(valueInGrammes);
				}
				//change all inputs according to grammes value
				var grammesValue = $('.unit-category-ctn.weight input.g').val();
				$('.unit-category-ctn.weight input').each(function() {
					if (!$(this).hasClass("g") && !$(this).hasClass(changedInput.attr('name'))) {
						var unit = $(this).attr('name');
						var calculatedValue = fromGrammesRouter(unit)(grammesValue);
						$(this).val(calculatedValue);
					}
				});
			}
		}
		else {
			$('.unit-category-ctn.weight input').each(function() {
				$(this).val("");
			});
		}
	}
	function convertPressure(changedInput) {
		if (changedInput.val() !== "") {
			if(changedInput.val().indexOf(',') != -1) {
				changedInput.val(changedInput.val().replace(/,/g, '.'));
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-' && isNaN(changedInput.val())) {
				alert("You did not enter a numeric value!");
				return;
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-') {
				if (!changedInput.hasClass("bar")) {
					var valueInBar = toBarRouter(changedInput.attr('name'))(changedInput.val());
					$('.unit-category-ctn.pressure input.bar').val(valueInBar);
				}
				//change all inputs according to grammes value
				var barValue = $('.unit-category-ctn.pressure input.bar').val();
				$('.unit-category-ctn.pressure input').each(function() {
					if (!$(this).hasClass("bar") && !$(this).hasClass(changedInput.attr('name'))) {
						var unit = $(this).attr('name');
						var calculatedValue = fromBarRouter(unit)(barValue);
						$(this).val(calculatedValue);
					}
				});
			}
		}
		else {
			$('.unit-category-ctn.pressure input').each(function() {
				$(this).val("");
			});
		}
	}
	function convertVolume(changedInput) {
		var cylinderData = allCylinderDataFilled();
		var isCylinderData = changedInput.hasClass('h') || changedInput.hasClass('diameter') || changedInput.hasClass('unite');
		
		
		var changedInputValue;
		if(changedInput.prop("tagName") === "INPUT") {
			changedInputValue = changedInput.val();
		}
		else if (changedInput.prop("tagName") === "SELECT") {
			changedInputValue = $("option:selected", changedInput).val();
		}
		
		if (changedInput.prop("tagName") !== "SELECT") {
			if (changedInputValue !== "") {
				if(changedInput.val().indexOf(',') != -1) {
					changedInput.val(changedInput.val().replace(/,/g, '.'));
				}
				if (changedInput.val() !== '.' && changedInput.val() !== '-' && isNaN(changedInput.val())) {
					alert("You did not enter a numeric value!");
					return;
				}
				else {
					if (!isCylinderData) {
						if (!changedInput.hasClass("l")) {
							var valueInLitres = toLitresRouter(changedInput.attr('name'))(changedInput.val());
							$('.unit-category-ctn.volume input.l').val(valueInLitres);
						}
						var litresValue = $('.unit-category-ctn.volume input.l').val();
						$('.unit-category-ctn.volume input').each(function() {
							if (!($(this).hasClass('h') || $(this).hasClass('diameter'))) {
								if (!$(this).hasClass("l") && !$(this).hasClass(changedInput.attr('name'))) {
									var unit = $(this).attr('name');
									var calculatedValue = fromLitresRouter(unit)(litresValue);
									$(this).val(calculatedValue);
								}
							}
						});
					}
				}
			}
			else {
				if (!isCylinderData) {
					$('.unit-category-ctn.volume input').each(function() {
						$(this).val("");
					});
				}
				
			}
		}
		
		
		if(isCylinderData) {
			if (cylinderData.isFilled) {
				var cylinderVolume = round(((cylinderData.diameter * cylinderData.diameter) / 4) * Math.PI * cylinderData.height);
				
				if (cylinderData.unit === "yards") {
					$('.unit-category-ctn.volume input.yd3').val(cylinderVolume);
					convertVolume($('.unit-category-ctn.volume input.yd3'));
				}
				else if (cylinderData.unit === "feet") {
					$('.unit-category-ctn.volume input.ft3').val(cylinderVolume);
					convertVolume($('.unit-category-ctn.volume input.ft3'));
				}
				else if (cylinderData.unit === "meters") {
					$('.unit-category-ctn.volume input.m3').val(cylinderVolume);
					convertVolume($('.unit-category-ctn.volume input.m3'));
				}
			}
		}
		
		
	}
	
	
	function convertDegree(changedInput) {
		if (changedInput.val() !== "") {
			if(changedInput.val().indexOf(',') != -1) {
				changedInput.val(changedInput.val().replace(/,/g, '.'));
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-' && isNaN(changedInput.val())) {
				alert("You did not enter a numeric value!");
				return;
			}
			if (changedInput.val() !== '.' && changedInput.val() !== '-') {
				if (!changedInput.hasClass("c")) {
					var valueInCelsius = toCelsiusRouter(changedInput.attr('name'))(changedInput.val());
					$('.unit-category-ctn.degrees input.c').val(valueInCelsius);
				}
				//change all inputs according to grammes value
				var celsiusValue = $('.unit-category-ctn.degrees input.c').val();
				$('.unit-category-ctn.degrees input').each(function() {
					if (!$(this).hasClass("c") && !$(this).hasClass(changedInput.attr('name'))) {
						var unit = $(this).attr('name');
						var calculatedValue = fromCelsiusRouter(unit)(celsiusValue);
						$(this).val(calculatedValue);
					}
				});
			}
		}
		else {
			$('.unit-category-ctn.degrees input').each(function() {
				$(this).val("");
			});
		}
	}
	
	function allCylinderDataFilled() {
		var heightVal = $('.unit-category-ctn.volume input.h').val();
		var diameterVal = $('.unit-category-ctn.volume input.diameter').val();
		var unitVal = $('.unit-category-ctn.volume select.unite option:selected').val();
		return {
			"height": heightVal,
			"diameter": diameterVal,
			"unit": unitVal,
			"isFilled": heightVal && diameterVal && unitVal
		};
	}
	
	
	
	function isNumber(n) {
	  return !isNaN(parseFloat(n)) && isFinite(n);
	}
	function round(number) {
		return +(number).toFixed(roundDecimal).replace(/\.0+$/, ''); //casting value to a number removes trailing zeros 
	}
	
	
	
	
})(jQuery);
