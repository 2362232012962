(function($, undefined) {
	
	'use strict';
	var currPage;
	
	var lang = $("html").attr('lang');
	
	var formValidator = Fw.component.models().form;
	var formCtn = $('.sell-to-us-form-ctn', currPage);
	var form = $('.sell-to-us-form', formCtn);
	var formBtn = $('.sell-to-us-btn', form);
	
	var init = function (page) {
		currPage = $(page);
		
		formValidator.init(currPage);
		formBtn.click(submitForm);
		
		localStorage.setItem('pageId', '');
	};
	
	
	function submitForm() {
			
			
		formValidator.validateForm(form);
		
		if (!form.hasClass('has-error')) {
			var email = $('input[name="email"]', form).val();
			var firstName = $('input[name="first-name"]', form).val();
			var phone = $('input[name="phone"]', form).val();
			var company = $('input[name="company"]', form).val();
			var message = $('textarea[name="information"]', form).val();
			var headerSuccessMsg = $('.header-success-message');
			
			var captchaResponse = grecaptcha.getResponse();
			
			
			var subject;
			if (lang === 'fr') {
				subject = "Vente d'équipement sur Equipack";
			} else if (lang === 'es') {
				subject = "Venta de equipos en Equipack";
			} else {
				subject = "Equipment sale on Equipack";
			}
			var firstNameFieldName;
			if (lang === 'fr') {
				firstNameFieldName = "Prénom";
			} else if (lang === 'es') {
				firstNameFieldName = "Nombre";
			} else {
				firstNameFieldName = "First name";
			}
			var phoneFieldName;
			if (lang === 'fr') {
				phoneFieldName = "Téléphone";
			} else if (lang === 'es') {
				phoneFieldName = "Teléfono";
			} else {
				phoneFieldName = "Phone";
			}
			var companyFieldName;
			if (lang === 'fr') {
				companyFieldName = "Nom de la compagnie";
			} else if (lang === 'es') {
				companyFieldName = "Nombre de la compañia";
			} else {
				companyFieldName = "Company name";
			}
			
			
			var url = '/action/contactUs';
			
			url += '?captcha=' + captchaResponse;
			url += '&subject=' + subject;
			url += '&email=' + email;
			url += '&content=' + encodeURIComponent(message);
			url += '&fieldName1=' + firstNameFieldName;
			url += '&field1=' + encodeURIComponent(firstName);
			url += '&fieldName2=' + phoneFieldName;
			url += '&field2=' + encodeURIComponent(phone);
			if (company) {
				url += '&fieldName3=' + companyFieldName;
				url += '&field3=' + encodeURIComponent(company);
			}
			url += '&count=' + 4;
			url += '&ajax=true';		
		
			$.ajax({
				url: url,
				method: 'POST',
				success: function(response) {
					if (response.trim() === "success") {
						
						form[0].reset();
						grecaptcha.reset();
						$("html, body").animate({ scrollTop: 0 }, "slow");
						setTimeout(function() {
							headerSuccessMsg.slideDown();
							setTimeout(function() {
								headerSuccessMsg.slideUp();
							}, 5000);
						}, 500);
						
						// save info
						// 0 = sell-to-us
						// 1 = equipment needs
						var url = '/action/manageGenData';
						
						url += '?field1=0';
						url += '&field2=' + email;
						url += '&field3=' + firstName;
						url += '&field4=' + phone;
						url += '&field5=' + message;
						if (company) {
							url += '&field6=' + company;
						}
						url += '&ajax=true';
						
						$.ajax({
							url: url,
							method: 'POST',
							success: function(response) {
								
							},
							error: function(xhr, status, err) {
								console.log(status + ' ' + err);
							}
						});
					}
				},
				error: function(xhr, status, err) {
					console.log("There was an error sending the form " + err);
				}
			});
		}
		else {
			console.log("there was an error sending the form");
		}
	}
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3706' : '3279';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
