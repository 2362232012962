(function($, undefined) {
	
	'use strict';
	
	var stopYTVideo = function (player) {
		player.stopVideo();
	};
	
	var pauseYTVideo = function (player) {
		player.pauseVideo();
	};
	
	var playYTVideo = function (player) {
		player.playVideo();
	};
	
	
	var ytControls = Fw.component.export('ytControls', {
		stopYTVideo: stopYTVideo,
		pauseYTVideo: pauseYTVideo,
		playYTVideo: playYTVideo
	});
	
})(jQuery);
