(function($, undefined) {
	
	'use strict';
	var currPage;
	
	var lang = $("html").attr('lang');
	var formValidator = Fw.component.models().form;
	var formCtn = $('.contact-us-form-ctn', currPage);
	var form = $('.contact-us-form', formCtn);
	var formBtn = $('.contact-us-btn', form);
	
	var map;
	
	
	var init = function (page) {
		currPage = $(page);
		
		formValidator.init(currPage);
		formBtn.click(submitForm);
		
		initMap();
		localStorage.setItem('pageId', '');
	};
	
	function initMap() {
		var myLatLng = {lat: 45.080571, lng: -74.162633};
		map = new google.maps.Map(document.getElementById('map'), {
			center: myLatLng,
			zoom: 15
		});
		var iconImg = '/static/uploaded/Files/icon-map-google-map.png';
		var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
			animation: google.maps.Animation.DROP,
			title: 'Équipack',
			icon: iconImg
		});
		google.maps.event.addListener(marker, 'click', function() {
			window.open(
			  'https://www.google.ca/maps/place/Atelier+202/@45.080475,-74.1648707,17z/data=!3m1!4b1!4m5!3m4!1s0x4ccbe2ca400a0229:0xe4539024307d754c!8m2!3d45.080475!4d-74.162682',
			  '_blank' 
			);
		});
	}
	
	function submitForm() {
			
		formValidator.validateForm(form);
		
		if (!form.hasClass('has-error')) {
			var email = $('input[name="email"]', form).val();
			var firstName = $('input[name="first-name"]', form).val();
			var phone = $('input[name="phone"]', form).val();
			var company = $('input[name="company"]', form).val();
			var message = $('textarea[name="information"]', form).val();
			var subscribeToNewsletterChecked = $('input[name="isSubscribe"]:checked', form).length > 0;
			var headerSuccessMsg = $('.header-success-message');
			
			var captchaResponse = grecaptcha.getResponse();
			
			var subject;
			if (lang === 'fr') {
				subject = "Nouvelle demande de contact Equipack";
			} else if (lang === 'es') {
				subject = "Nueva solicitud de contacto";
			} else {
				subject = "New inquiry on Equipack";
			}
			var firstNameFieldName;
			if (lang === 'fr') {
				firstNameFieldName = "Prénom";
			} else if (lang === 'es') {
				firstNameFieldName = "Nombre";
			} else {
				firstNameFieldName = "First name";
			}
			var phoneFieldName;
			if (lang === 'fr') {
				phoneFieldName = "Téléphone";
			} else if (lang === 'es') {
				phoneFieldName = "Teléfono";
			} else {
				phoneFieldName = "Phone";
			}
			var companyFieldName;
			if (lang === 'fr') {
				companyFieldName = "Nom de la compagnie";
			} else if (lang === 'es') {
				companyFieldName = "Nombre de la compañia";
			} else {
				companyFieldName = "Company name";
			}
			
			var url = '/action/contactUs';
			
			url += '?captcha=' + captchaResponse;
			url += '&subject=' + subject;
			url += '&email=' + email;
			url += '&content=' + encodeURIComponent(message);
			url += '&fieldName1=' + encodeURIComponent(firstNameFieldName);
			url += '&field1=' + firstName;
			url += '&fieldName2=' + encodeURIComponent(phoneFieldName);
			url += '&field2=' + phone;
			if (company) {
				url += '&fieldName3=' + encodeURIComponent(companyFieldName);
				url += '&field3=' + company;
			}  
			url += '&count=' + 4;
			url += '&ajax=true';		
			
			
			$.ajax({
				url: url,
				method: 'POST',
				success: function(response) {
					if(response.trim() === 'success') {
						form[0].reset();
						grecaptcha.reset();
						$("html, body").animate({ scrollTop: 0 }, "slow");
						setTimeout(function() {
							headerSuccessMsg.slideDown();
							setTimeout(function() {
								headerSuccessMsg.slideUp();
							}, 5000);
						}, 500);
						
						setTimeout(function() {
							// Handle subscribe to newsletter
							if(subscribeToNewsletterChecked) {
								window.open(
								  'https://app.cyberimpact.com/clients/6842/subscribe-forms/FDECE63F-14EE-42E8-92B8-8EF53F4C0D02',
								  '_blank' 
								);
							}
						}, 5000);
					}
				},
				error: function(xhr, status, err) {
					console.log("There was an error sending the form");
				}
			});
		}
	}
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3711' : '3288';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
