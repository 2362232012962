var pageId = $('div.page').data("pageIdNum");


var onloadCallback = function() {
	if (isPageWithCaptcha(pageId)) {
		grecaptcha.render('recaptcha-container', {
			'sitekey' : '6LcVYBYUAAAAAEkhxq6KZV_fVS4H2_aZYBLuyQyK'
		});
	}
};

function isPageWithCaptcha(id) {
	return id === 3706 || id === 3707 || id === 3711 || id === 3279 || id === 3283 || id === 3288;
}
