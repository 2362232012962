(function($, undefined) {
	
	'use strict';
	var doc = $(document);
	var currPage;
	var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd otransitionend';
	var body;
	var items;
	var checkLabel;
	var checkboxItems;
	var checkboxItemsInner;
	var file;
	var fileInput;
	var formSwitch;
	var formCheck;
	
	var validateForm = function (ctn) {
		ctn.each(function () {
			var t = $(this);
			var toValidateItems = $('.form-input.required', t);
			var toValidateUpload = $('.form-input-upload.required', t);
			var toValidateCheck = $('.form-check-input.required', t);
			var password = $('.js-password', t);
			var passwordConfirm = $('.js-password-confirm', t);
			var btn = $('.js-form-btn', t);
			var isOk = true;
			var passwordOk = true;
			var termsOk = true;
			var fieldsErrorMessage = $('.js-form-validate-fields-error', t);
			var fieldsErrorTerms = $('.form-error-message.terms', t);
			var fieldsErrorPassword = $('.form-error-message.password', t);
			
			toValidateItems.each(function () {
				var tChild = $(this);
				
				validateField(tChild);
					
				if (tChild.hasClass('error')) {
					isOk = false;
				}
			});
			
			toValidateUpload.each(function () {
				var tUpload = $(this);
				var input = $('.form-input-upload-input', tUpload);
				
				if (input.val() === '') {
					tUpload.addClass('error');
				}
				
				if (tUpload.hasClass('error')) {
					isOk = false;
				}
			});
			
			toValidateCheck.each(function () {
				var tCheck = $(this);
				var tCtn = tCheck.closest('.form-checkbox-ctn');
				
				if (!tCheck.is(':checked')) {
					tCtn.addClass('error');
				} else {
					tCtn.removeClass('error');
				}
				
				if (tCheck.attr('name') === 'terms' && tCtn.hasClass('error')) {
					termsOk = false;
				}
				
				if (tCtn.hasClass('error')) {
					termsOk = false;
				}
			});
			
			if (password.length !== 0 && passwordConfirm.length !== 0 && (password.val() !== passwordConfirm.val() || password.val().length === 0)) {
				if (password.val().length === 0) {
					password.removeClass('success').addClass('error');
					passwordOk = false;
				} else {
					password.addClass('success').removeClass('error');
					passwordOk = true;
				}
				
				if (password.val() !== passwordConfirm.val()) {
					passwordConfirm.removeClass('success').addClass('error');
					passwordOk = false;
				} else {
					passwordConfirm.removeClass('error');
					passwordOk = true;
					
					if (password.val().length !== 0) {
						passwordConfirm.addClass('success');
					}
				}
			}
			
			t.addClass('is-checked');
			
			if (!termsOk) {
				fieldsErrorTerms.slideDown(300);
			} else {
				fieldsErrorTerms.slideUp(300);
			}
			
			if (password.length !== 0 && passwordConfirm.length !== 0) {
				if (password.length !== 0 && passwordOk) {
					ctn.addClass('has-password');
					password.add(passwordConfirm).removeClass('error');
					fieldsErrorPassword.slideUp(300);
				} else {
					ctn.removeClass('has-password');
					password.add(passwordConfirm).addClass('error');
					fieldsErrorPassword.slideDown(300);
				}
			}
			
			if (!isOk) {
				fieldsErrorMessage.slideDown(300);
			} else {
				fieldsErrorMessage.slideUp(300);
			}
			
			if (!isOk || !passwordOk || !termsOk) {
				btn.addClass('disabled');
				ctn.addClass('has-error');
				
				if (!passwordOk && password.hasClass('required')) {
					ctn.addClass('error-password');
				}
				return false;
			} else {
				btn.removeClass('disabled');
				ctn.removeClass('has-error').removeClass('error-password');
				
				fieldsErrorTerms.slideUp(300);
				return true;
			}
		});
	};
	
	var validateField = function (elt) {
		var tag = elt[0].tagName;
		var type = elt.attr('type');
		var val = elt.val();
		var attrClass = elt.attr('data-validator-apply-class-on');
		var classTarget = attrClass !== undefined ? elt.closest(attrClass) : elt;
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		
		if (tag === 'INPUT' && type === 'email') {
			var emailValid = re.test(val);
			
			if (emailValid) {
				classTarget.removeClass('error').addClass('success');
			} else {
				classTarget.addClass('error').removeClass('success');
			}
		} else if (elt.hasClass('form-input-upload-input')) {
			if (classTarget.hasClass('required') && elt.val() !== '') {
				classTarget.removeClass('error').addClass('success');
			} else {
				classTarget.addClass('error').removeClass('success');
			}
		} else {
			classTarget.addClass('success').removeClass('error');
			
			if (classTarget.hasClass('required') && val.length === 0) {
				classTarget.removeClass('success').addClass('error');
			} else if (!classTarget.hasClass('required') && val.length === 0) {
				classTarget.removeClass('success').removeClass('error');
			}
		}
	};
	
	var formItemsBlur = function (t) {
		var elt = t.target === undefined ? t : $(this);
		var ctn = elt.closest('.form-input');
		var form = ctn.closest('.js-form');
		
		validateField(elt);
		
		if (form.hasClass('is-checked')) {
			validateForm(form);
		}
	};
	
	var formCheckChange = function () {
		var t = $(this);
		var ctn = t.closest('.form-checkbox-ctn');
		var form = ctn.closest('.js-form');
		
		if (form.hasClass('is-checked')) {
			validateForm(form);
		}
	};
	
	var checkLabelClick = function () {
		var t = $(this);
		var ctn = t.closest('.register-checkbox');
		
		if (input.hasClass('is-checked')) {
			input.removeClass('is-checked');
		} else {
			input.addClass('is-checked');
		}
	};
	
	var toggleCheckbox = function () {
		var t = $(this);
		var ctn = t.closest('.form-input-checkbox');
		var input = $('.form-input-checkbox-input', t);
		
		if (ctn.hasClass('checked')) {
			ctn.removeClass('checked');
			input.removeAttr('checked');
		} else {
			ctn.addClass('checked');
			input.attr('checked', 'checked');
		}
	};
	
	
	var fileInputChange = function () {
		var t = $(this);
		var label = t.closest('.form-input-upload-input-label');
		var labelDefault = label.attr('data-default-value');
		var labelValue = $('.form-input-upload-input-label-value', label);
		var ctn = t.closest(t.attr('data-validator-apply-class-on'));
		var form = ctn.closest('.js-form');
		
		if (t.val() !== '') {
			ctn.removeClass('error').addClass('success');
			labelValue.html(t.val().split('\\')[t.val().split('\\').length - 1]);
			
		} else {
			ctn.addClass('error').removeClass('success');
			labelValue.html(labelDefault);
		}
		
		if (form.hasClass('is-checked')) {
			validateForm(form);
		}
	};
	
	
	var formSwitchClick =function (){
		var t = $(this);
		var elem = $(t).find('.checkbox');

		t.find('.form-checkbox-slider').toggleClass('active');
		if (elem.prop( "checked" )){
			elem.removeAttr( "checked" );
		}else{
			elem.attr( "checked", "checked" );
		}
	};
	
	
	var init = function (page) {
		currPage = $(page);
		body = $('body');
		items = $('.form-input:not([type=file])', currPage);
		checkLabel = $('.register-checkbox-label', currPage);
		checkboxItems = $('.form-input-checkbox', currPage);
		checkboxItemsInner = $('.form-input-checkbox-inner', checkboxItems);
		file = $('.form-input-upload', currPage);
		fileInput = $('.form-input-upload-input', file);
		formSwitch = $('.form-switch', currPage);
		formCheck = $('.form-check-input', currPage);
		
		
		items.each(function () {
			if ($(this).val() !== '') {
				formItemsBlur($(this));
			}
		});
		
		checkLabel.click(checkLabelClick);
		checkboxItemsInner.click(toggleCheckbox);
		fileInput.change(fileInputChange);
		formSwitch.click(formSwitchClick);
		formCheck.change(formCheckChange);
		
		items.blur(formItemsBlur);
		
	};
	
	
	var form = Fw.component.export('form', {
		init: init,
		validateForm: validateForm
	});
	
})(jQuery);
