(function($, undefined) {
	
	'use strict';
	var editableText;
	
	
	var moveEditableText = function () {
		var text = $('.editable-text-ctn');
		
		text.each(function () {
			var t = $(this);
			var ctn = t.parent('.js-editable-container');
			var pos;
			var receiver;
			
			if (t.attr('data-position') !== 0) {
				pos = t.attr('data-position');
			} else {
				pos = 1;
			}
			
			if ($('.js-editable-receiver[data-editable-receiver-position = '+pos+']', ctn).length !== 0) {
				receiver = $('.js-editable-receiver[data-editable-receiver-position = '+pos+']', ctn);
			} else {
				receiver = $('.js-editable-receiver', ctn);
			}
			
			if ($('.js-editable-receiver[data-editable-receiver-position]', ctn).length !== 0 && 
				$('.js-editable-receiver[data-editable-receiver-position = '+pos+']', ctn).length === 0 && 
				t.attr('data-position') !== '') {
				t.remove();
			} else if (ctn.length !== 0 && receiver.length !== 0) {
				t.detach().appendTo(receiver);
			}
		});
	};
	
	var addEditableTextClass = function () {
		$('.componentContainerDiv').addClass('js-editable-container');
		
		editableText.each(function () {
			var t = $(this);
			var parent = t.parent();
			
			parent.addClass('editable-text-ctn');
		});
	};
	
	var addEditableTextPosition = function () {
		$('.js-editable-container').each(function () {
			var index = 1;
			var t = $(this);
			
			$('.editable-text-ctn', t).each(function () {
				var t = $(this);
				
				t.attr('data-position', index);
				
				index++;
			});
		});
	};
	
	
	var init = function (page) {
		var currPage = $(page);
		
		editableText = $('div[id^=displayTextInnerWindow]', currPage);
		
		addEditableTextClass.apply(currPage);
		addEditableTextPosition.apply(currPage);
		moveEditableText.apply(currPage);
	};
	
	
	var modEditableText = Fw.modules.export('modEditableText', {
		pageLoad: init
	});
	
})(jQuery);
