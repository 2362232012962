/*
*	MG2 Media AJAX librairy
*	@author  François Vandal
*	@version: 0.9.9
*	Last update: 20 october 2016, by Franck
*/

(function($, global, undefined) {
	
	'use strict';
	var win = $(window);
	var html = $('html');
	var body = $('body');
	var currentId;
	var currentSection;
	var createdContent;
	var animations = {};
	var titles = {};
	var loader;
	var lang = html.attr('lang');
	var defaultLang = html.attr('data-default-lang');
	var firstPageToLoad;
	
	
	/* This is the start of the page loader: The listener on the links. 
		It checks if the link is an inner page, gets the info of the link and
		starts the animation function. */
	var linksClick = function (e) {
		var link = $(e.currentTarget);
		var targetLink = link.attr('href');
		
		if (targetLink === ('/' + lang + '/')) {
			targetLink = 'home';
		} else {
			targetLink = targetLink !== '/' ? link.attr('href') : 'home';
		}
		
		if (!(link.attr('target') === '_blank' || 
			targetLink.indexOf('javascript:') === 0 ||
			targetLink.indexOf('mailto:') === 0 ||
			targetLink.indexOf('tel:') === 0 ||
			targetLink.indexOf('http://') === 0 || 
			targetLink.indexOf('https://') === 0) &&
			(e.ctrlKey === false && e.which === 1 &&
			e.altKey === false && e.shiftKey === false) &&
			targetLink.substring(0, 1) !== '#' &&
			!link.hasClass('site-lang-btn') &&
			!link.hasClass('no-ajax')) {
			
			if (!html.hasClass('ajax-in-progress')) {
				var targetLinkPathname = link.attr('href');
				var targetLinkPathnameFormat = link[0].pathname.substring(1);
				var targetLinkDataLocation = link.data('ajaxUrl') !== undefined ? link.data('ajaxUrl') : '.main-content';
				var targetLinkAnimation = link.data('ajaxAnimation') !== undefined ? link.data('ajaxAnimation') : 'fade';
				var localId = link.data('ajaxId');
				
				ajaxUrl(targetLinkPathname, targetLinkDataLocation, targetLinkAnimation, targetLinkPathnameFormat, targetLink, true, localId);
			}
			
			//Prevent normal click behaviour
			return false;
		}
	};
	
	/* Main function of the core. It takes the page key and info it needs to generate
		the content in the right spot. */ 
	var ajaxUrl = function (url, targetElement, targetLinkAnimation, targetLinkPathnameFormat, pageId, pushHistory, localId, data) {
		var ajaxUrlToLoad = url;
		var nextPageIdToCheck = ajaxUrlToLoad
		var target = $(targetElement);
		var loading = '<div class="ajax-popup-loading"></div>';
		html.addClass('ajax-in-progress');
		var currPage = $('html').attr('data-curr-page');
		var completePageId;
		var isLocalAjax = targetElement === '.main-content' ? false : true;
		var isLocalExists = false;
		var localCallItem;
		var hasParams = false;
		var paramsVal = '';
		var contentExists = true;
		var targetElementToCheck;
		var hashValue = '';
		
		/*if (ajaxUrlToLoad.substring(0, 1) === '/') {
			ajaxUrlToLoad = ajaxUrlToLoad.substring(1);
		}*/
		
		pageId = split(pageId, '?');
		pageId = split(pageId, '#');
		if (pageId.substring(pageId.length - 1) === '/') {
			pageId = pageId.substring(0, pageId.length - 1);
		}
		
		if (pageId.substring(0, 1) === '/') {
			pageId = pageId.substring(1);
		}
		
		if (lang !== defaultLang) {
			if(pageId.substring(0, lang.length) === lang) {
				pageId = pageId.substring(lang.length);
			}
		}
		
		if (pageId.substring(0, 1) === '/') {
			pageId = pageId.substring(1);
		}
		
		var isFriendlyURL = true;
		
		
		/* This manages the content with URL parameters */
		if (ajaxUrlToLoad.indexOf('?') > -1 && ajaxUrlToLoad.split('?')[1].length !== 0) {
			hasParams = true;
			paramsVal = ajaxUrlToLoad.split('?')[1];
		}
		
		for (var p=0; p < Object.keys(Fw.keyPagesName).length; p++) {
			if (pageId === Object.keys(Fw.keyPagesName)[p]) {
				isFriendlyURL = false;
			}
		}
		
		if (isFriendlyURL) {
			pageId = pageId.split('/')[0];
		} else {
			pageId = pageId.split('/')[pageId.split('/').length - 1];
		}
		
		completePageId = pageId;
		nextPageIdToCheck = nextPageIdToCheck.replace('/', '-');
		
		//Prevent further operation if the link targets the same page
		if ('page-' + nextPageIdToCheck === Fw.currentPage) {
			html.removeClass('ajax-in-progress');
			return false;
		}
		
		var elePage = $('#page-'+pageId);
		var parameteredPages = $('.parametered-pages', elePage);
		var contentPage = $('.page-content', elePage);
		
		if (isFriendlyURL) {
			var paramsVal = ajaxUrlToLoad;
			
			if (paramsVal.substring(paramsVal.length - 1) === '/') {
				paramsVal = paramsVal.substring(0, paramsVal.length - 1);
			}
			
			paramsVal = paramsVal.split('/').pop();
			hasParams = true;
		}
		
		if (hasParams && !isLocalAjax) {
			if ($('.parametered-pages .parametered-page[data-parametered-id="'+paramsVal+'"]', elePage).length !== 0) {
				var parametedPage = $('.parametered-page', parameteredPages);
				var parametedPageToShow = $('.parametered-page[data-parametered-id="'+paramsVal+'"]', parameteredPages);
				
				if (Fw.currentPage === 'page-'+pageId) {
					scrollUp(targetElement, function () {
						contentPage.fadeOut(300);
						$.when(parametedPage.fadeOut(300)).then(function parametedPageFadeingIn () {
							parameteredPages.fadeOut(0);
							parametedPage.removeClass('page-open');
							parametedPageToShow.fadeIn(0).addClass('page-open');
							
							if (pushHistory) {
								window.history.pushState({'pageId': ajaxUrlToLoad}, pageId, ajaxUrlToLoad);
							}
							
							parameteredPages.fadeIn(300);
						});
					});
				} else {
					contentPage.fadeOut(0);
					parameteredPages.fadeIn(0);
					
					parametedPage.fadeOut(0).removeClass('page-open');
					parametedPageToShow.fadeIn(0).addClass('page-open');
				}
			} else {
				contentExists = false;
			}
		}
		
		if (!hasParams && !isLocalAjax) {
			if (contentPage.length !== 0) {
				if (Fw.currentPage === 'page-'+pageId) {
					scrollUp(targetElement, function () {
						$.when(parameteredPages.fadeOut(300)).then(function contentPageFadeIn () {
							if (pushHistory) {
								window.history.pushState({'pageId': ajaxUrlToLoad}, pageId, ajaxUrlToLoad);
							}
							
							contentPage.fadeIn(300);
						});
						
						html.removeClass('ajax-in-progress');
					});
				} else {
					contentPage.fadeIn(0);
					parameteredPages.fadeOut(0);
				}
			} else {
				contentExists = false;
			}
		}
		
		/* This manages the exeptions for AJAX calls inside a page */
		if(isLocalAjax) {
			target = target.parent();
			
			localCallItem = $(targetElement + '[data-ajax-id = ' + localId + ']', target);
			
			if (localCallItem.length !== 0) {
				isLocalExists = true;
			}
		}
		
		//If the page is already created, only the animation on the page-container is triggered
		if (elePage.length >= 1 && !isLocalAjax && contentExists) {
			if (currPage !== 'page-'+completePageId) {
				scrollUp(targetElement, function () {
					//Push the next page in the browser's history
					if (pushHistory) {
						window.history.pushState({'pageId': ajaxUrlToLoad}, pageId, ajaxUrlToLoad);
					}
					
					//Change page title
					document.title = titles[pageId];
					
					//Start the functions in pageStartChange right before the animation
					$.each(Fw.modules.models(), function () {
						this.pageStartChange('#page-' + pageId);
					});
					
					//Start the animation
					animations[targetLinkAnimation]($('#'+currPage, target), target, $('#page-'+completePageId), pageId, loading, false);
				});
			}
		} else if (isLocalExists) {
			//If it's a  local AJAX call and it has already been created before
			var currentLocal = $(targetElement + ':not(.hidden)', target);
			targetElementToCheck = targetElement;
				
			if (localId !== parseInt(currentLocal.attr('data-ajax-id'))) {
				var ajaxUrlToLoadToHistory = ajaxUrlToLoad;
				var ajaxTargetToHistory = targetElementToCheck;
				localCallItem.removeClass('hidden');
				
				currentLocal.fadeOut(300, function() {
					currentLocal.addClass('hidden');
					
					//window.history.pushState({'pageId': ajaxUrlToLoadToHistory, 'localreceiver': ajaxTargetToHistory}, pageId, ajaxUrlToLoadToHistory);
					
					localCallItem.fadeIn(300);
				});
			}
		} else {
			var urlToLoad = '';
			
			lang !== defaultLang && (urlToLoad = '/' + lang);
			
			if (ajaxUrlToLoad.substring(0, 1) === '/') {
				ajaxUrlToLoad = ajaxUrlToLoad.substring(1);
			}
			
			if (lang !== defaultLang) {
				if(ajaxUrlToLoad.substring(0, lang.length) === lang) {
					ajaxUrlToLoad = ajaxUrlToLoad.substring(lang.length);
				}
			}
			
			ajaxUrlToLoad = ajaxUrlToLoad === '/' ? 'home' : ajaxUrlToLoad;
			
			urlToLoad = urlToLoad + '/' + ajaxUrlToLoad;
			
			targetElementToCheck = targetElement;
			var hasFriendlyUrl = isFriendlyURL;
			
			//If the page hasn't been created before the AJAX function is triggered
			
			html.append('<div class="ajax-loader"></div>');
			loader = $('.ajax-loader');
			loader.fadeOut(0);
			loader.fadeIn(200);
			loader.animate({width: '10%'}, 200);
			
			$.ajax({
				url: urlToLoad,
				cache: false,
				type: 'get',
				dataType: 'html',
				success: function(data) {
					var parsedData = $.parseHTML(data);
					$('a', parsedData).attr('href', function () {
						return removeURLParameter($(this).attr('href'), '_');
					});
					
					//Getting and parsing the data to get only the part wanted
					var content = targetElement !== '.main-content' ? $(targetElement, parsedData) : $('.page', parsedData);
					var pageTag = ajaxUrlToLoad;
					if (hasFriendlyUrl) {
						pageTag = pageTag.substr(0, pageTag.lastIndexOf('/'));
					}
					pageTag = split(pageTag, '?');
					pageTag = pageTag.replace(/\//g, '-');
					pageTag = split(pageTag, '#');
					var page = $('#page-'+ pageTag);
					var historyUrlToShow = ajaxUrlToLoad === 'home' ? '' : ajaxUrlToLoad;
					
					if ((Fw.currentPage !== content.attr('id') || hasParams) && !isLocalAjax || $('.page-content', page).length === 0 && !hasParams && !isLocalAjax) {
						scrollUp(targetElement, function() {
							
							//History and title change
							var title = $(data).filter('title').text();
							window.history.pushState({'pageId': ajaxUrlToLoad}, pageId, historyUrlToShow);
							document.title = title;
							titles[pageId] = title;
							
                            if (page.length === 0) {
                                //Place it right in there! Oh yeah baby!
                                content.appendTo(target);
                            } else {
                                if (hasParams) {
                                    var parameterCtn = $('.parametered-pages', page);
									$('.page-content', page).fadeOut(0);
									parameterCtn.fadeIn(0);
									
									if (parameterCtn.length === 0) {
										var newParameteredPageCtn = $('.parametered-pages', parsedData);
										//Place it right in there! Oh yeah baby!
										newParameteredPageCtn.appendTo(page);
									} else {
										var newParameteredPage = $('.parametered-page', parsedData);
										
										$('.parametered-page', parameterCtn).fadeOut(0);
										$('.parametered-page', page).removeClass('page-open');
										newParameteredPage.appendTo(parameterCtn);
									}
                                } else {
                                    var newContentPage = $('.page-content', parsedData);
                                    var oldContentPage = $('.page-content', page);
                                    
                                    if (oldContentPage.length !== 0) {
                                    	oldContentPage.replaceWith(newContentPage);
                                    } else {
                                    	newContentPage.appendTo(page);
                                    }
									
									if (Fw.currentPage === content.attr('id')) {
										$('.page-content', page).fadeOut(0);
										$('.parametered-pages', page).fadeOut(300, function newContentPageFadeIn () {
											$('.parametered-page', page).removeClass('page-open');
											$('.page-content', page).fadeIn(300);
										});
									} else {
										$('.parametered-pages', page).fadeOut(0);
										$('.parametered-page', page).removeClass('page-open');
									}
                                }
                            }
							
							//Place the HTML of the whole loaded page in the Framework
							Fw.data = parsedData;
							
							//Start the functions in pageStartChange right before the animation
							$.each(Fw.modules.models(), function () {
								this.pageStartChange('#page-' + pageId);
							});
							
							
							pageTag = ajaxUrlToLoad;
							if (hasFriendlyUrl) {
								pageTag = pageTag.substr(0, pageTag.lastIndexOf('/'));
							}
							pageTag = split(pageTag, '?');
							pageTag = pageTag.replace(/\//g, '-');
							pageTag = split(pageTag, '#');
							var pageIdNum = $('#page-'+pageTag).data('pageIdNum');
							
							//Animation start
							animations[targetLinkAnimation]($('#'+Fw.currentPage, target), target, content, pageId, loading, true, pageIdNum);
						});
					} else if (isLocalAjax) {
						/* This manages the inner-page-AJAX-class' response. */
						var currentLocal = $(targetElement + ':not(.hidden)', target);
						var nextLocal = content;
						var ajaxUrlToLoadToHistory = ajaxUrlToLoad;
						var ajaxTargetToHistory = targetElementToCheck;
						nextLocal.appendTo(target);
						
						nextLocal.fadeOut(0);
						currentLocal.fadeOut(300, function isLocalAjaxNewFade() {
							currentLocal.addClass('hidden');
							
							//window.history.pushState({'pageId': ajaxUrlToLoadToHistory, 'localreceiver': ajaxTargetToHistory}, pageId, ajaxUrlToLoadToHistory);
							
							nextLocal.fadeIn(300);
						});
					}
				},
				statusCode: {
					403: function() {
						alert("YOU SHALL NOT PASS. Access is forbidden. Check the target URL.");
					},
					404: function() {
						alert("Page not found. Check the target URL.");
					},
					418: function() {
						alert("We all are a teapot in our hearts.");
					}
				}
			});
		}
	};
	
	/* Default animation, this animation fadeOut the element to replace
		and fadeIn the newly created element */
	var animateFadeInOut = function (current, target, next, pageId, loading, isNew, pageIdNum) {
		next.fadeOut(0);
		current.fadeOut(300, function() {
			target.parent().append(loading);
			
			var newPopupEleSelect = $('.ajax-popup-loading', target.parent());
			newPopupEleSelect.css('opacity', '1');
			
			animateStart(target, next, pageId, pageIdNum);
			
			createdContent.fadeIn(300, function() {
				newPopupEleSelect.animate({opacity: '0'}, 100, function() {
					newPopupEleSelect.remove();
					html.removeClass('ajax-in-progress');
					$('.ajax-popup-loading'). remove();
					
					animateEnd(isNew, pageId, pageIdNum);
				});
			});
		});
	};
	
	/* Sliding functions to slide the content from left to right or right to left.
		The content has to start at '99%' or '-99%' because it has to be in the window
		even thaugh it is invisible for the paint to execute properly */
	var slideToLeft = function (current, target, next, pageId, loading, isNew, pageIdNum) {
		slideAnim('-99%', '100%', current, target, next, pageId, loading, isNew, pageIdNum);
	};
	
	var slideToRight = function (current, target, next, pageId, loading, isNew, pageIdNum) {
		slideAnim('99%', '-100%', current, target, next, pageId, loading, isNew, pageIdNum);
	};
	
	var slideToTop = function (current, target, next, pageId, loading, isNew, pageIdNum) {
		slideAnimVertical('99%', '-100%', current, target, next, pageId, loading, isNew, pageIdNum);
	};
	
	var slideToBottom = function (current, target, next, pageId, loading, isNew, pageIdNum) {
		slideAnimVertical('-99%', '-100%', current, target, next, pageId, loading, isNew, pageIdNum);
	};
	
	var slideAnim = function (createdStart, prevEnd, current, target, next, pageId, loading, isNew, pageIdNum) {
		animateStart(target, next, pageId, pageIdNum);
		
		current.css({position: 'relative', left: '0%'});
		createdContent.fadeIn(0);
		createdContent.css({position: 'absolute', left: createdStart, top: '0%'});
		
		current.animate({left: prevEnd}, 700, function() {
			current.fadeOut(0);
			current.css({left: '0%'});
			animateEnd(isNew, pageId, pageIdNum);
		});
		
		createdContent.animate({left: '0%'}, 700, function() {
			html.removeClass('ajax-in-progress');
			createdContent.css({'position': 'relative', left: '0%'});
		});
	};
	
	var slideAnimVertical = function (createdStart, prevEnd, current, target, next, pageId, loading, isNew, pageIdNum) {
		animateStart(target, next, pageId, pageIdNum);
		
		current.css({position: 'relative', top: '0%'});
		createdContent.fadeIn(0);
		createdContent.css({position: 'absolute', top: createdStart, left: '0%'});
		
		current.animate({top: prevEnd}, 700, function() {
			current.fadeOut(0);
			current.css({top: '0%'});
			animateEnd(isNew, pageId, pageIdNum);
		});
		
		createdContent.animate({top: '0%'}, 700, function() {
			html.removeClass('ajax-in-progress');
			createdContent.css({'position': 'relative', top: '0%'});
		});
	};
	
	/* Function that takes care of recurent tasks in the beginning of animations 
		Add a class 'anim-in-progress', change the page class on the body
		Checks the links href to clear out the cachebuster parameter that AJAX can add */
	var animateStart = function (target, next, pageId, pageIdNum) {
		//Start the functions in pageLeave right before the animation
		$.each(Fw.modules.models(), function () {
			this.pageLeave('#page-' + pageId);
		});
		
		if (Fw.page.models()[currentId] !== undefined) {
			Fw.page.models()[currentId].pageLeave('#page-' + pageId);
		}
		
		body.removeClass(Fw.currentPage).removeClass('page-' + Fw.currentId);
		Fw.currentPage = next.attr('id');
		currentId = next.attr('data-page-id-num');
		Fw.currentId = currentId;
		body.addClass('page-' + next.attr('data-page-id')).addClass('page-' + Fw.currentId);
		loader.css('width', '50%');
		$('html').attr('data-curr-page', Fw.currentPage).addClass('anim-in-progress');
		
		//Start the functions in pageEnter right before the animation
		$.each(Fw.modules.models(), function () {
			this.pageEnter('#page-' + pageId);
		});
		
		if (Fw.page.models()[pageIdNum] !== undefined) {
			Fw.page.models()[pageIdNum].pageEnter('#page-' + pageId);
		}
		
		//Init of clicks in new content
		createdContent = $('#'+Fw.currentPage, target);
	};
	
	/* Function that takes care of recurent tasks at the end of animations 
	  remove the 'anim-in-progress' and calls pageCreatedCallback() */
	var animateEnd = function (isNew, pageId, pageIdNum) {
		$('html').removeClass('anim-in-progress');
		
		if (isNew) {
			pageCreatedCallback(pageIdNum);
		}
		
		$.each(Fw.modules.models(), function () {
			this.pageLoad('#' + Fw.currentPage);
			this.pageChange('#' + Fw.currentPage);
		});
		
		if (Fw.page.models()[Fw.currentId] !== undefined) {
			Fw.page.models()[Fw.currentId].pageLoad('#'+Fw.currentPage);
		}
		
		loader.css('width', '100%');
		setTimeout(function () {
			loader.fadeOut(200, function () {
				$(this).remove();
			});
		}, 500);
	};
	
	/* Gets the module with pageLoad and pageChange state and the page script in the FrameWork */
	var pageCreatedCallback = function (pageIdNum) {
		//Gets the init of a page model
		Fw.page.checkForPage(pageIdNum);
	};
	
	/* This functions is executed when the history is changed with a pushState()
		What it does is get the page key in the history and calls the ajax function
		to get the already created page (Since it's in the history, the page is always 
		going to be created beforehand)  */
	var winPopstate = function (e) {
		if (window.history.state !== null && window.history.state.pageId !== undefined) {
			//Block chrome de faire le call à l'ouverture de la première page pour rien
			//if(e.originalEvent.state !== null){
				var targetLink = window.history.state.pageId !== null ? window.history.state.pageId : firstPageToLoad;
				var targetLinkDataLocation = '.main-content';
				var targetLinkAnimation = 'fade';
				/*var currentIdFormated = Fw.currentId;
				
				if (currentIdFormated.substring(0, 1) === '/') {
					currentIdFormated = currentIdFormated.substring(1);
				}
				
				if (currentIdFormated === window.history.state.localreceiver) {
					
				} else {*/
					//The false parameter is to prevent a pushState() and mess up the history
					ajaxUrl(targetLink, targetLinkDataLocation, targetLinkAnimation, targetLink, targetLink, false, '');
				//}
			//}
		} else {
			window.location.href = window.location.href;
		}
	};
	
	/* Animation called when the AJAX is done loading its content.
		After the scroll is completed, the callback function is called with all the
		tasks to do */
	var scrollUp = function (targetElement, callback) {
		//Get the parent to scroll the page and (if present) the closest element with scroll-y: scroll
		var scrollParentCtn = $(targetElement);
		var currScroll = scrollParentCtn;
		scrollParentCtn.parents().each(function(e) {
			var c = $(this).css('overflow-y');
			if (c === 'scroll') {
				currScroll = $(this);
				return false;
			}
			return true;
		});
		
		/* If there is no element with scroll-y, therefor only the document has it 
			The animation has to be on both the html and the body to support all
			browsers #firefuck */
		if (currScroll === scrollParentCtn) {
			var target = 0;
			
			if ($('.site-header').css('position') !== 'fixed' && $('.site-header').offset().top > 0 ||
				$('.site-header').css('position') === 'undefined') {
				target = $('.site-header').offset().top;
			}
			
			$('html').animate({scrollTop: target}, 300);
			$('body').animate({scrollTop: target}, 300, callback);
		} else  {
			$('html').animate({scrollTop: currScroll.offset().top}, 300);
			$('body').animate({scrollTop: currScroll.offset().top}, 300);
			currScroll.animate({scrollTop: 0}, 300, callback);
		}
	};
	
	/* Function used to remove the cachebuster parameter */
	var removeURLParameter = function (url, parameter) {
		//prefer to use l.search if you have a location/link object
		if (url !== undefined) {
			var urlparts= url.split('?');   
			if (urlparts.length>=2) {

				var prefix= encodeURIComponent(parameter)+'=';
				var pars= urlparts[1].split(/[&;]/g);

				//reverse iteration as may be destructive
				for (var i= pars.length; i-- > 0;) {    
					//idiom for string.startsWith
					if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
						pars.splice(i, 1);
					}
				}

				url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
				return url;
			} else {
				return url;
			}
		}
	};
	
	var split = function (str, char) {
		var i = str.indexOf(char);

		if(i > 0)
			return str.slice(0, i);
		else {
			return str;
		}
	};
	
	
	
	win.load(function () {
		Fw.currentPage = $('.main-content .page').attr('id');
		currentId = $('.main-content .page').attr('data-page-id-num') ? $('.main-content .page').attr('data-page-id-num') : Fw.key[$('.main-content .page').attr('data-page-id')];
		Fw.currentId = currentId;
        
        currentSection = $('.main-content .page').attr('data-section');
        Fw.currentSection = currentSection;
		
		firstPageToLoad = currentId.substring(0, 1) === '/' ? currentId.substring(1) : currentId;
		
		if (!($('body').hasClass('is-logged-in'))) {
			/*$(document).on('click', 'a', linksClick);
			win.on('popstate', winPopstate);*/
			
			var titlesKey = location.pathname.substring(1);
			titlesKey = titlesKey.substring(0, 3) === (lang + '/') ? (titlesKey.substring(3)) : titlesKey;
			titlesKey = titlesKey === '' ? 'home' : titlesKey;
			titles[titlesKey] = document.title;
		}
		
		animations = {
			'fade': animateFadeInOut,
			'slideRight': slideToRight,
			'slideLeft': slideToLeft,
			'slideUp': slideToTop,
			'slideDown': slideToBottom
		};
	});
	
})(jQuery, window);