(function($, undefined) {
	
	'use strict';
	
	var prevState = "desktop";
	var state = "desktop";
	
	var form = Fw.component.models().form;
	var subcatSelect = $('.market-place-subcategory-select');
	// Safari 3.0+ "[object HTMLElementConstructor]" 
	var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
	
	
	function isMobile() {
		var isMobileDiv = $('.is-mobile');
		return isMobileDiv.css('visibility') === "visible";
	}
	
	function setState() {
		if (isMobile()) {
			return "mobile";
		}
		else {
			return "desktop";
		}
	}
	
	var init = function (page) {
		var currPage = $(page);
		
		if (isMobile() && isSafari) {
			$('.view-btn-ctn').css('height', '40px');
			$('.part-number-ctn').css('height', '20px');
		}
		
		$('.product-list-ctn:first-child').find('.market-place-category-subcategory-toggler').addClass('open');
		
		/* mobile drawer toggler */
		$('.market-place-category-subcategory-toggler').on('click', function(event) {
			$(this).parent().siblings('.products-ctn').slideToggle();
			$(this).toggleClass('open');
		});
		
		$(window).resize(function() {
			if ($(window).width() > 599) {
				$('.product-list-ctn:first-child').find('.market-place-category-subcategory-toggler').removeClass('open');
				$('.market-place-category-subcategory-toggler').parent().siblings('.products-ctn').show();
			}
			else {
				$('.product-list-ctn:first-child').find('.market-place-category-subcategory-toggler').addClass('open');
			}
			
			state = setState();
			if (state !== prevState && state == "mobile" && isSafari) {
				$('.view-btn-ctn').css('height', '40px');
				$('.part-number-ctn').css('height', '20px');
			}
			prevState = state;
		});
		
		
		
		/* Select subcategory to show / show all if none selected */
		subcatSelect.change(function(event) {
			var selectedId = $(this).find(':selected').val();
			var found = false;
			$('.product-list-ctn').hide();
			$('.product-list-ctn').each(function() {
				if ($(this).data('id') == selectedId) {
					$(this).show();
					$(this).find('.products-ctn').show();
					$(this).find('.market-place-category-subcategory-toggler').addClass('open');
					found = true;
				}
			});
			if (!found) {
				$('.product-list-ctn').show();
				$('.product-list-ctn').find('.products-ctn').hide();
				$('.product-list-ctn').find('.market-place-category-subcategory-toggler').removeClass('open');
				$('.product-list-ctn:first-child').find('.products-ctn').show();
				$('.product-list-ctn:first-child').find('.market-place-category-subcategory-toggler').addClass('open');
			}
		});
		
		form.init(currPage);
		
		var pageId = $('body').attr('class').split(' ')[1].substr(5, 8);
		localStorage.setItem('pageId', pageId);
	};
	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3723' : '3275';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
