(function($, undefined) {
	
	'use strict';
	
	var form = Fw.component.models().form;
	

	var init = function (page) {
		var currPage = $(page);
		
		$('.index-slider-ctn-inner').slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 1000,
			fade: true,
			arrows: false,
			dots: true,
			lazyLoad: 'progressive',
			slidesToShow: 1,
			slidesToScroll: 1,
            pauseOnFocus:false,
            pauseOnHover:false
		});
		
		form.init(currPage);
		
		localStorage.setItem('pageId', '');
	};
	

	
	//Toujours mettre le ID de la clef de la page
	//La clef est le /page/@name de la langue par défaut de la page
	var pageId = $('html').attr('data-is-dev') === 'true' ? '3703' : '3273';
	var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
