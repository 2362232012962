(function($, undefined) {
	
	'use strict';
	var isLoggedIn = false;
	var isMobile = false;
	var win = $(window);
	var winWidth;
	var winHeight;
	
	var header;
	var navItems;
	
	var toggleMenuBtn;
	
	
	var toggleScroll = function () {
		var diff;
		
		if ($('body').hasClass('no-scroll')) {
			$('body').removeClass('no-scroll');
			diff = 0;
		} else {
			var w1 = win.width();
			$('body').addClass('no-scroll');
			var w2 = win.width();
			diff = w2 - w1;
		}
		
		changeScrollDiff(diff);
	};
	
	var changeScrollDiff = function (diff) {
		$('.js-scroll-padding').css('padding-right', diff + 'px');
		$('.js-scroll-right').css('right', diff + 'px');
	};
	 
	var onClickMenuToggle = function () {
		if (!!header.hasClass('menu-open')) {
			var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd otransitionend';
			header.removeClass('menu-open');
			
			header.one(transitionEnd, function(e) {
				$('body').removeClass('no-scroll');
				changeScrollDiff(0);
				$(this).off(e);
			});
		} else {
			var target = 0;
			
			if ($('.site-header').css('scroll-y') !== 'fixed' && $('.site-header').offset().top > 0) {
				target = $('.site-header').offset().top;
			}
			
			if (win.scrollTop() <= $('.site-header').offset().top) {
				$('html').animate({scrollTop: target}, 300);
				$('body').animate({scrollTop: target}, 300, function () {
					openMenu();
				});
			} else {
				openMenu();
			}
		}
	};
	
	var openMenu = function () {
		header.addClass('menu-open');
		toggleScroll();
	};
	
	var closeMenu = function () {
		if (header.hasClass('menu-open')) {
			header.removeClass('menu-open');
			toggleScroll();
		}
	};
	
	var updateNavSelected = function () {
		navItems.removeClass('selected');
		var currPage = $('html').attr('data-curr-page');
		var handleObj = $('.main-content .page[id = '+currPage+']');
		var handle = handleObj.attr('data-page-id');
		
		navItems.each(function () {
			var t = $(this);
			
			if (t.data('pageNav') === 'page-' + handle) {
				t.addClass('selected');
			}
		});
	};
	
	var updateNavSelected = function (page) {
		navItems.removeClass('selected');
		var handleObj = $('.main-content ' + page);
		var handle = handleObj.attr('data-page-nav');
		
		navItems.each(function () {
			var t = $(this);
			
			if (t.data('pageNav') === handle) {
				t.addClass('selected');
			}
		});
	};
	
	var pageEnter = function (page) {
		updateNavSelected(page);
	};
	
	var init = function (page) {
		var currPage = $(page);
		
		winWidth = win.width();
		winHeight = win.height();
		$('body').removeClass('no-scroll');
		
		header = $('.site-header', page);
		navItems = $('.nav-item-link', header);
		
		toggleMenuBtn = $('.site-nav-menu-toggle-btn', header);
		
		if($('body').hasClass('is-logged-in')) {
			isLoggedIn = true;
		}
		
		toggleMenuBtn.on('click', onClickMenuToggle);
		navItems.on('click', closeMenu);
		
		$('.header-lang-selector').on('click', function() {
			$('.header-lang-dropdown').toggleClass('show');
		});
		
		$(document).on('click', function(e) {
		   if (!$(e.target).closest('.header-lang-dropdown').length && 
		   	   !$(e.target).closest('.header-lang-selector').length &&
		   	    $('.header-lang-dropdown').hasClass('show')) {
		   	
		        $('.header-lang-dropdown').removeClass('show');
		    }
		    if (!$(e.target).closest('.search-form-ctn').length && 
		    	   !$(e.target).closest('.search-form-mobile').length &&
		    	   !$(e.target).closest('.search-input-mobile').length &&
		    	   !$(e.target).closest('.mobile-menu-search-icon').length &&
		    	    $('.search-form-ctn').hasClass('show')) {
		    	
		        $('.search-form-ctn').removeClass('show');
		     	searchOpenMobile = !searchOpenMobile;
		     }
		     if ( !$(e.target).closest('.search-form').length &&
		     	  !$(e.target).closest('.search-input').length &&
		     	  !$(e.target).closest('.search-icon').length &&
		     	   searchOpenDesktop) {
		     	
		        closeSearchIconDesktop.toggle();
		        searchInputDesktop.css("padding", "8px 0");
		        searchInputDesktop.animate({width: 'toggle'}, 500);
		        searchOpenDesktop = !searchOpenDesktop;
		      }
		});
		
		
		/* Search icon animation desktop*/
		var searchOpenDesktop = false;
		var searchIconDesktop = $('.search-icon');
		var searchInputDesktop = $('.search-input');
		var closeSearchIconDesktop = $('.close-search-icon');
		searchIconDesktop.click(function() {
			if(!searchOpenDesktop) {
				searchInputDesktop.css("padding", "8px 0");
				searchInputDesktop.animate({width: 'toggle'}, 500, function() {
					searchInputDesktop.css('padding', '8px');
					closeSearchIconDesktop.toggle();
				});
				searchOpenDesktop = !searchOpenDesktop;
			}
			else {
				if (searchInputDesktop.val() !== "") {
					$('.search-form').submit();
				}
			}
		});
		closeSearchIconDesktop.click(function() {
			if(searchOpenDesktop) {
				closeSearchIconDesktop.toggle();
				searchInputDesktop.css("padding", "8px 0");
				searchInputDesktop.animate({width: 'toggle'}, 500);
				searchOpenDesktop = !searchOpenDesktop;
			}
		});
		
		/* search icon animation mobile */
		var searchOpenMobile = false;
		var searchIconMobile = $('.mobile-menu-search-icon', currPage);
		var searchInputMobile = $('.search-input-mobile', currPage);
		var searchFormMobile = $('.search-form-ctn', currPage);
		var closeSearchIconMobile = $('.close-search-icon-mobile', currPage);
		searchIconMobile.click(function(){
			/* Open search field if not already opened*/
			if (!searchOpenMobile) {
				searchFormMobile.addClass('show');	
				searchOpenMobile = !searchOpenMobile;
			}
			/* Send search to Search action if search field open*/
			else {
				if(searchInputMobile.val() !== "") {
					$('.search-form-mobile').submit();
				}
			}
		});
		closeSearchIconMobile.click(function() {
			if(searchOpenMobile) {
				searchFormMobile.removeClass('show');	
				searchOpenMobile = !searchOpenMobile;
			}
		});
		
		win.resize(function() {
			if (win.width() != winWidth) {
				winHeight = win.height();
				winWidth = win.width();
			}
		});
	};
	
	
	var header = Fw.modules.export('header', {
		init: init,
		pageEnter: pageEnter
	});
	
})(jQuery);
