(function($, undefined) {
	
	'use strict';
	var currPage;
	
	var formValidator = Fw.component.models().form;
	var formCtn;
	var form;
	var formBtn;
	var cartItemsCount;
	
	var init = function (page) {
		currPage = $(page);
		
		formCtn = $('.cart-step-2-form-ctn', currPage);
		form = $('.cart-step-2-form', formCtn);
		formBtn = $('.cart-step-2-form-ctn .cart-step-2-btn');
		cartItemsCount = $('.quote-request-items-list').data('count');
		
		formValidator.init(currPage);
		formBtn.click(submitForm);
		
		localStorage.setItem('pageId', '');
	};
	
	function submitForm() {
		formValidator.validateForm(form);
		
		if (!form.hasClass('has-error') && cartItemsCount > 0) {
			var email = $('input[name="email"]', form).val();
			var firstName = $('input[name="first-name"]', form).val();
			var phone = $('input[name="phone"]', form).val();
			var company = $('input[name="company"]', form).val();
			var message = $('textarea[name="information"]', form).val();
			var subscribeToNewsletterChecked = $('input[name="isSubscribe"]:checked', form).length > 0;
			var headerSuccessMsg = $('.header-success-message');
			
			//var captchaResponse = grecaptcha.getResponse();
			
			var url = '/action/shopBypassPayment';
			
			//url += '?captcha=' + captchaResponse;
			url += '?email=' + email;
			url += '&firstName=' + encodeURIComponent(firstName);
			url += '&phone=' + phone;
			if (company) {
				url += '&field2=' + encodeURIComponent(company);
			}
			if (message) {
				url += '&comments=' + encodeURIComponent(message);
			}
			url += "&emailPDF=true";
			url += '&ajax=true';	
			
			$.ajax({
				url: url,
				method: 'POST',
				success: function(response) {
					console.log(response);
					
					if(response.trim() === 'success') {
						form[0].reset();
						$("html, body").animate({ scrollTop: 0 }, "slow");
						setTimeout(function() {
							headerSuccessMsg.slideDown();
							setTimeout(function() {
								headerSuccessMsg.slideUp();
							}, 3000);
						}, 500);
						
						setTimeout(function() {
							// Handle subscribe to newsletter
							if(subscribeToNewsletterChecked) {
								window.open(
								  'https://app.cyberimpact.com/clients/6842/subscribe-forms/FDECE63F-14EE-42E8-92B8-8EF53F4C0D02',
								  '_blank' 
								);  
							}
                            
						}, 3000);
                        emptyPage();  
						
					}
				},
				error: function(xhr, status, err) {
					console.log("There was an error sending the form " + status + " " + err);
				}
			});
		} else {
			if (form.hasClass('has-error')) {
				console.log('there was an error in the form.');
			}
			if (cartItemsCount <= 0) {
				console.log('cart is empty, cannot send empty quote request.');
			}
			
		}	
	}

    var emptyPage = function() {
        $(".cart-step-2-form-ctn",currPage).remove();
        $(".quote-request-items-list",currPage).remove();
        $(".my-cart-step-2-text-ctn",currPage).remove();
        $(".my-cart-submitted",currPage).slideDown();        
    };

    //Toujours mettre le ID de la clef de la page
    //La clef est le /page/@name de la langue par défaut de la page
    var pageId = $('html').attr('data-is-dev') === 'true' ? '3727' : '3277';
    var home = Fw.page.export(pageId, {
		init: init
	});
	
})(jQuery);
