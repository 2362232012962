(function($, undefined) {
	
	'use strict';
	var videoCtn;
	var video;
	var ctrl = Fw.component.models().ytControls;
	var timer;
	Fw.allPlayers = [];
	
	
	var pageLeave = function (page) {
		var currPage = $(page);
		
		for (var i = 0;i < Fw.allPlayers.length;i++) {
			if (Fw.allPlayers[i].page === Fw.currentPage) {
				ctrl.stopYTVideo(Fw.allPlayers[i].player);
			}
		}
	};
	
	var initVideoPlayers = function () {
		if (YT.loaded === YT.loading) {
			videoCtn.each(function() {
				var t = $(this);
				var id = t.data('id');
				var playlist = t.data('playlistId');
				var duplicate = false;
				
				for (var o = 0;o < Fw.allPlayers.length;o++) {
					if (Fw.allPlayers[o].elt === t || t[0].tagName === 'IFRAME') {
						duplicate = true;
					}
				}
				
				if (!duplicate) {
					var player = new YT.Player(t[0], {
					height: '1080',
					width: '1920',
					videoId: id,
					playerVars: {
						listType:'playlist',
						list: playlist
					}
					});
					
					Fw.allPlayers.push({player: player, page: Fw.currentPage, elt: t, id: id});
				}
			});
		} else {
			setTimeout(function () {
				initVideoPlayers();
			}, 100);
		}
	};
	
	var pageLoad = function (page) {
		var currPage = $(page);
		videoCtn = $('.yt-player', currPage);
		
		initVideoPlayers();
	};
	
	var youtubeVideos = Fw.modules.export('youtubeVideos', {
		pageLeave: pageLeave,
		pageLoad: pageLoad
	});
	
})(jQuery);
